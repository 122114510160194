<template>
  <div class="flex items-center w-full">
    <text-input
      :label='label'
      :input.sync='val'
      @update:input="$emit('update:input', val)"
      type="text"
      :valid="valid"
      :disable="!editMode"
      @enter="saved"
    >
      <p
        v-if="!disable"
        @click="saved"
        class="pl-2 text-sm underline cursor-pointer text-content edit-text"
      >
        {{editMode ? "Save" : "Edit"}}
      </p>
    </text-input>
  </div>
</template>

<script>
import TextInput from '@/components/shared/inputs/TextInput.vue';

export default {
  name: 'ClickTextInput',
  components: {
    TextInput,
  },
  data() {
    return {
      editMode: false,
      val: '',
    };
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    input: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'text',
      validator: (val) => ['password', 'email', 'text'].includes(val),
    },
    valid: {
      type: Boolean,
      required: true,
    },
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created() {
    this.val = this.input;
  },
  methods: {
    saved() {
      if (this.valid) {
        this.editMode = !this.editMode;

        if (this.editMode === false) {
          this.$emit('save');
        } else {
          document.querySelector(`input[name="${this.label}"]`).click();
        }
      } else if (!this.editMode) {
        this.editMode = true;
      }
    },
  },
};
</script>
