<template>
  <div
    class="`
      flex flex-row border flex-grow-0 flex-shrink-0 border-contrast
      justify-between items-center
      rounded-md w-72 px-3 py-1 mt-4 md:w-80 lg:w-96 bg-backfill
      shadow-sm
      settings-child-component
    `"
  >
      <p class="text-content">{{ name }}</p>
      <check-box
        @click="$emit('click')"
        v-if="canDelete"
        :checked="checked"
        :color="color"
      />
  </div>
</template>

<script>

import CheckBox from '@/components/shared/inputs/CheckBox.vue';

export default {
  name: 'SettingsChildComponent',
  props: {
    name: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    canDelete: {
      type: Boolean,
      requried: true,
    },
  },
  components: {
    CheckBox,
  },
};
</script>

<style>

</style>
