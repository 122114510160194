<template>
  <button
    @click="$emit('click')"
    :disabled="disable"
    :class="[
      alert ? 'bg-red-500' : 'bg-primary',
      disable ? 'cursor-not-allowed text-content' : 'hover:text-content'
    ]"
    class="`
      flex flex-shrink-0 flex-grow-0 justify-center items-center
      px-2 py-1 text-sm font-bold text-white rounded
      focus:outline-none
    `"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'PrimaryButtonSmall',
  props: {
    alert: {
      type: Boolean,
      required: false,
      default: false,
    },
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
