<template>
<!-- eslint-disable vue/no-parsing-error -->
  <div class="flex flex-row items-start w-full">
    <label
      class="flex flex-col items-start w-full"
    >
      <div class="relative flex flex-row items-center w-full text-left">
        <span class="text-secondary"> {{label}} </span>
        <span v-if="!valid" class="text-xs text-red-500">* Required</span>
        <div class="flex justify-end flex-grow">
          <slot />
        </div>
      </div>
      <div class="flex flex-row items-start w-full">
        <input
          @keyup="onKeyUp($event.target.value)"
          :name="label"
          autocomplete="off"
          :value="input"
          :type="type"
          :placeholder="placeholder"
          :readonly="disable"
          @keyup.enter="$emit('enter')"
          :class="[
            disable ? 'border-transparent cursor-default' :
              valid ? 'border-secondary' : 'border-red-600',
          ]"
          class="w-full bg-transparent border-b-2 outline-none focus:outline-none text-input"
        />
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    label: {
      type: String,
      required: true,
    },
    input: {
      type: [String, Number],
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'text',
      validator: (val) => ['password', 'email', 'text', 'number'].includes(val),
    },
    valid: {
      type: Boolean,
      required: true,
    },
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onKeyUp(val) {
      this.$emit('update:input', val);
    },
    validate(evt) {
      if (this.type !== 'number') return;

      const theEvent = evt || window.event;
      let key = null;
      // Handle paste
      if (theEvent.type === 'paste') {
        key = theEvent.clipboardData.getData('text/plain');
      } else {
      // Handle key press
        key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
      }

      const regex = /^[0-9]+$/i;
      const result = regex.test(key);
      const result2 = key.match(regex);
      console.log(result);
      console.log(result2);
      if (!result) {
        theEvent.returnValue = false;
      }
    },
  },
};
</script>

<style>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
</style>
