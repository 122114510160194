<template>
  <label
    class="`
        flex flex-col items-start w-full text-secondary
      `"
  >
    <p>
      <span class="text-secondary"> {{label}} </span>
      <span v-if="!valid" class="text-xs text-red-500">* Required</span>
    </p>
    <select
      @change="updateInput"
      :value="input"
      :name="label"
      :disabled="disable"
      class="`
        w-full bg-transparent outline-none focus:outline-none text-input
        border-transparent focus:border-secondary cursor-pointer
      `"
    >
    <option v-for="item in items" :key="item" :value="item">{{item}}</option>
    </select>
    <p></p>
  </label>
</template>

<script>
export default {
  name: 'ClickDropdownInput',
  props: {
    label: {
      type: String,
      required: true,
    },
    input: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    valid: {
      type: Boolean,
      required: true,
    },
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    updateInput(event) {
      this.$emit('update:input', event.target.value);
      this.$emit('save', event.target.value);
    },
  },
};
</script>
