<template>
    <interior-page-content-container
      nav-selection=""
      @back-click="backNav"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="true"
      :modal-show="modal.deleteChecklist || modal.deleteItems"
      :loading="loading"
      :notificationMessage.sync="notificationMessage"
      :notificationTime="notificationTime"
      :notificationType="notificationType"
    >

      <template #notification>
        {{notification.message}}
      </template>

      <template #modal>
        <modal-confirmation
          v-if="modal.deleteChecklist"
          title="Delete Checklist?"
          description="
            If you delete the checklist all of it's data will
            be lost as well as all user data associated with this checklist.
            This action is permanent and cannot be undone.
          "
          text-confirm="Delete"
          text-cancel="Cancel"
          @cancel="modal.deleteChecklist = false"
          @confirm="modalConfirmChecklistDelete"
        />

        <modal-confirmation
          v-if="modal.deleteItems"
          title="Delete Checklist Items?"
          description="
            If you delete the checklist items all of their data will
            be lost as well as all user data associated with these checklist items.
            This action is permanent and cannot be undone.
          "
          text-confirm="Delete"
          text-cancel="Cancel"
          @cancel="modal.deleteItems = false"
          @confirm="modalConfirmItemsDelete"
        />
      </template>

      <template #title>
        Checklist Settings
      </template>

      <template #content>
        <div class="`
        flex flex-col items-center self-center justify-center flex-shrink-0
        pb-4 mb-10 md:w-96 mx-7 pt-2
        `"
        >

          <div class="flex items-center justify-center h-48">
            <img
              :src="picture"
              alt="Checklist Photo"
              class="object-scale-down max-h-full rounded-lg shadow-lg select-none"
            />
          </div>
          <p
            @click="takePicture"
            class="py-2 text-sm underline cursor-pointer text-content"
          >
            Update
          </p>
          <input
            @change="replacePicture" v-show='false'
            type="file" accept="image/*"
          />

          <div class="flex flex-col items-center self-center justify-center flex-shrink-0 w-full">
            <div class="w-full mb-3 text-2xl font-thin text-left text-content">Details</div>

            <click-text-input
              @save="put('name')"
              :input.sync='checklist.name'
              :valid='checklistValidity.nameValid'
              label='Checklist Name'
              type='text'
            />
            <click-dropdown-input
              @save="put('type')"
              :input.sync='checklist.type'
              :items="['Service', 'Safety']"
              :valid='true'
              label='Type'
              type='text'
            />
          </div>

          <div class="flex flex-col items-center self-center justify-center flex-shrink-0 w-full">
            <div class="`
              flex flex-row justify-between
              w-full mt-8 mb-2 text-2xl font-thin text-left text-content
            `">
              <p>Checklist Items</p>
              <p
                v-if="checklist.items.length > 0 "
                @click="toggleChecklistEdit"
                class="text-base underline cursor-pointer text-secondary edit-items"
              >
                {{editItems ? "Done" : "Edit" }}
              </p>
            </div>

            <p v-if="checklist.items.length === 0" class="text-content">
              You currently do not have any checklist items.
            </p>

            <settings-child-component
              v-for="(item) in checklist.items"
              @click="itemClicked(item.id)"
              :key="item.name"
              :name="item.name"
              :checked="itemsToDelete.includes(item.id)"
              :canDelete="editItems"
              color="red"
              class="text-white"
            />
            <div class="flex flex-row justify-end w-full mt-2 h-7">
              <p
                v-if="editItems && itemsToDelete.length > 0"
                @click="modal.deleteItems = true"
                class="text-base underline cursor-pointer text-secondary"
              >
                Delete
              </p>
            </div>

          </div>

          <primary-button-small
            @click="modal.deleteChecklist = true"
            :alert="true"
            class="mt-10"
          >
            Delete Checklist
          </primary-button-small>

        </div>
      </template>
    </interior-page-content-container>
</template>

<script>

import {
  ADMIN_CHECKLIST_GET,
  ADMIN_CHECKLIST_PUT,
  ADMIN_CHECKLIST_DELETE,
  ADMIN_CHECKLIST_ITEM_DELETE,
} from '@/store/actions';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import PrimaryButtonSmall from '@/components/shared/Buttons/PrimaryButtonSmall.vue';
import ClickTextInput from '@/components/shared/inputs/ClickTextInput.vue';
import ClickDropdownInput from '@/components/shared/inputs/ClickDropdownInput.vue';
import ModalConfirmation from '@/components/shared/inputs/ModalConfirmation.vue';
import SettingsChildComponent from '@/components/projects/SettingsChildComponent.vue';

export default {
  name: 'AdminChecklistSettings',
  components: {
    InteriorPageContentContainer,
    PrimaryButtonSmall,
    SettingsChildComponent,
    ClickTextInput,
    ModalConfirmation,
    ClickDropdownInput,
  },
  data() {
    return {
      toDelete: false,
      itemsToDelete: [],
      checklistValidity: {
        nameValid: true,
        typeValid: true,
      },
      modal: {
        deleteChecklist: false,
        deleteItems: false,
      },
      editItems: false,
      loading: true,
      saving: false,
      newPicture: null,
      checklist: {},
      notification: {
        show: false,
        type: 'alert',
        message: '',
      },
      notificationMessage: '',
      notificationTime: 5000,
      notificationType: 'success',
    };
  },
  computed: {
    valid() {
      return this.checklistValidity.nameValid && this.checklistValidity.typeValid;
    },
    picture() {
      if (this.newPicture) return URL.createObjectURL(this.newPicture);
      return this.checklist.picture.url;
    },
    defaultBackRoute() {
      return {
        name: 'AdminChecklist',
        params: {
          checklist_id: this.$route.params.checklist_id,
        },
      };
    },
  },
  watch: {
    checklist: {
      handler(newVal, oldVal) {
        if (oldVal.name !== null) {
          this.checklistValidity.nameValid = newVal.name.length > 0;
          this.checklistValidity.typeValid = newVal.type.length > 0;
        }
      },
      deep: true,
    },
    newPicture() {
      this.put('picture');
    },
  },
  created() {
    this.$store.dispatch(ADMIN_CHECKLIST_GET, this.$route.params.checklist_id)
      .then((checklist) => {
        this.checklist = checklist;
        this.checklist.items = this.checklist.items.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        });
      })
      .finally(() => { this.loading = false; });
  },
  methods: {
    toggleChecklistEdit() {
      this.editItems = !this.editItems;
      this.itemsToDelete = [];
    },
    put(updateField) {
      this.saving = true;
      const putChecklist = {};

      if (updateField === 'name') {
        putChecklist.name = this.checklist.name;
      } else if (updateField === 'type') {
        putChecklist.type = this.checklist.type;
      } else if (updateField === 'picture') {
        putChecklist.picture = this.newPicture;
      }

      return this.$store.dispatch(ADMIN_CHECKLIST_PUT, {
        checklistId: this.$route.params.checklist_id,
        checklistData: putChecklist,
      })
        .catch((error) => {
          // TODO Test this properly, make the http requests
          //  fail and then do it or something
          if (error.response.status === 400) {
            this.notificationMessage = error.response.data;
            this.notificationType = 'alert';
            this.notificationType = 7000;
          } else {
            this.notificationMessage = 'An error has occured. Please try again later.';
            this.notificationType = 'alert';
            this.notificationType = 7000;
          }
        })
        .finally(() => { this.saving = false; });
    },
    itemClicked(index) {
      if (this.itemsToDelete.includes(index)) {
        this.itemsToDelete = this.itemsToDelete.filter(
          (itemIndex) => itemIndex !== index,
        );
      } else this.itemsToDelete.push(index);
    },
    replacePicture(event) {
      // eslint-disable-next-line prefer-destructuring
      this.newPicture = event.target.files[0];
    },
    takePicture() {
      document.querySelector('input[type="file"]').click();
    },
    modalConfirmChecklistDelete() {
      this.actionComplete = true;
      this.$store.dispatch(ADMIN_CHECKLIST_DELETE, this.$route.params.checklist_id)
        .then(() => {
          this.$router.push({
            name: 'AdminChecklists',
          });
        });
    },
    async modalConfirmItemsDelete() {
      this.modal.deleteItems = false;
      const { items } = this.checklist;
      const promises = [];
      this.saving = true;
      this.itemsToDelete.forEach((itemId) => {
        promises.push(
          this.$store.dispatch(ADMIN_CHECKLIST_ITEM_DELETE, {
            checklistId: this.$route.params.checklist_id,
            itemId,
          }),
        );
      });
      await Promise.all(promises);
      this.saving = false;
      this.checklist.items = items.filter(
        (item) => !this.itemsToDelete.includes(item.id),
      );
      this.itemsToDelete = [];
    },
    backNav() {
      if (!this.saving) this.$router.push(this.defaultBackRoute);
    },
  },

};
</script>
